import { Component, Inject } from '@angular/core';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { SubSystem } from '../../../enumerators/classifier.enum';
import { IConfig } from '../../../models/config.interface';

@Component({
  selector: 'app-public-search',
  templateUrl: './public-search.component.html'
})
export class PublicSearchComponent {
  module: SubSystem = SubSystem.PUB;
  publicSearchListFormId: string = this.environment.formIds.publicSearchListFormId;

  constructor(
    @Inject(APP_CONFIG) private readonly environment: IConfig
  ) {
    
  }
}
